/* #selectId {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
} */

#selectId {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  position: absolute;
  padding: 0px;
  width: 25px;
  text-align: center;
  font-size: 20px;
  background: transparent;
  font-weight: bold;
  outline: none;
}

/* For IE10 */
#selectId::-ms-expand {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media print {
  @page {
    size: A4;
    margin: 20px;
  }
  .page-break {
    page-break-before: always !important;
  }
}
